$(document).ready(function() { ui.init(), modules.hashNav(), modules.video("intro-video"), modules.spoilers({ selector: ".js-spoiler" }), modules.tabs({ selector: ".js-tabs" }), plugins.counterUp(), plugins.wayPoint(), plugins.parallax(), plugins.countDown(), plugins.popup(), plugins.carousel(), plugins.sliders(), forms.init("body") });
var ui = function(a) {
        "use strict";

        function b() {
	         $("html").addClass(helpers.mobile() ? "m-touch" : "m-desktop"),
	         $(a).on("load", function() {
	         	$("html").addClass("m-loaded"),
	         	$(".b-loader").addClass("b-loader__complete", !0)
	      	});
      	}

        function c() {
            function b() {
                var a = $(".b-leaflet_locker"),
                    b = a.length ? Math.abs(parseInt(a.css("margin-top")), 10) : document.documentElement.scrollTop || document.body.scrollTop;
                c.toggleClass("b-header__transparent", d && e > b)
            }
            var c = $(".b-header__float"),
                d = $("#intro").length > 0,
                e = 10;
            b(), $(a).bind("scroll.headerState resize.headerState", function() { b() })
        }

        function d() {
            var a = $(".js-nav"),
                b = $("#header"),
                c = $(".b-leaflet_locker"),
                d = 10;
            a.bind("click", function() {
                var a = c.length ? Math.abs(parseInt(c.css("margin-top")), 10) : document.documentElement.scrollTop || document.body.scrollTop;
                b.toggleClass("b-header__transparent", b.hasClass("b-header__showNav") && d > a).toggleClass("b-header__showNav")
            })
        }

        function e() {
            var a = $(".b-pricing_switcher_box input"),
                b = $(".b-pricing_plan");
            a.bind("change", function() { b.toggleClass("b-pricing_plan__switched", $(this).prop("checked")) })
        }

        function f() { $("[data-ticket]").on("click", function() { $("html, body").stop().animate({ scrollTop: $($(this).attr("href")).offset().top - 70 }, 1500, "easeInOutExpo"), $($(this).attr("href")).find('select option[value="' + $(this).data("ticket") + '"]').prop("selected", !0), $($(this).attr("href")).find('select option[value="' + $(this).data("validity") + '"]').prop("selected", !0), $.uniform.update() }) }

        function g() {
            var b = location.search,
                c = {};
            if (b)
                for (var d = b.substr(1).split("&"), e = 0; e < d.length; e++) {
                    var f = d[e].split("=");
                    c[f[0]] = f[1]
                }
            "success" == c.paypal && $(a).leafLetPopUp("show", { url: "assets/php/success.php", animationStyleOfBox: "flip3d", animationStyleOfChange: "slide", boxWidth: 500, boxHorizontalGutters: 15, closeBtnClass: "i-icon i-ion-ios-close-outline", closeBtnLocation: "overlay", directionBtnClass: ["i-icon i-ion-ios-arrow-back", "i-icon i-ion-ios-arrow-forward"], overlayOpacity: .75, afterLoad: function() { $(".b-header_nav a").removeClass("active") } })
        }

        function h() {
            $('a[data-target="_blank"]').on("click", function() {
                return !a.open($(this).attr("href"))
            })
        }
        return { init: function() { b(), c(), d(), e(), f(), g(), h() } }
    }(window),
    helpers = function(a) {
        "use strict";

        function b() {
            return "devicePixelRatio" in a && a.devicePixelRatio > 1
        }

        function c() {
            var b = $(a).width();
            return 480 > b ? "xs" : b >= 480 && 768 > b ? "sm" : b >= 768 && 970 > b ? "md" : b >= 970 ? "lg" : void 0
        }

        function d() {
            var a = {
                android: function() {
                    return navigator.userAgent.match(/Android/i)
                },
                blackBerry: function() {
                    return navigator.userAgent.match(/BlackBerry/i)
                },
                iOS: function() {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
                },
                opera: function() {
                    return navigator.userAgent.match(/Opera Mini/i)
                },
                windows: function() {
                    return navigator.userAgent.match(/IEMobile/i)
                }
            };
            return a.android() || a.blackBerry() || a.iOS() || a.opera() || a.windows()
        }
        return { mobile: d, pixelsRatio: b, screen: c }
    }(window),
    forms = function(a) {
        "use strict";

        function b(a) { forms.styleControls(a + ' input[type="checkbox"], ' + a + ' input[type="radio"]', a + " select"), forms.makePlaceholders(a + " [placeholder]"), forms.validate(a) }

        function c(b) {
            $(b + " form").each(function() {
                var b = $(this);
                $.isFunction($.fn.validate) && b.data("checkup") && b.validate({
                    onChange: !0,
                    eachValidField: function() { formNotifications.hideErrorLabel.call($(this)) },
                    eachInvalidField: function(a, b) {
                        var c = "undefined" != typeof $(this).data("conditionalType") ? formNotifications.labels.conditional[$(this).data("conditionalType")] : formNotifications.labels.conditional["default"],
                            d = "undefined" != typeof $(this).data("patternType") ? formNotifications.labels.pattern[$(this).data("patternType")] : formNotifications.labels.pattern["default"],
                            e = b.required ? b.conditional ? b.pattern ? "" : d : c : formNotifications.labels.required;
                        formNotifications.showErrorLabel.call($(this), e, 0)
                    },
                    valid: function(b) {
                        var c = $(this),
                            d = $(this).find('button[type="submit"].b-btn'),
                            e = $(this).data("callback"),
                            f = $(this).data("xhr");
                        return "function" == typeof a[e] ? (b.preventDefault(), a[e].call(c), !1) : void(f && (b.preventDefault(), $.ajax({ url: c.attr("action"), method: c.attr("method"), data: c.serialize(), dataType: "json", beforeSend: function() { d.toggleClass("request") }, success: function(a) { d.toggleClass("request"), xhrFormHandler.response.call(c, a) } })))
                    }
                }).find("input, textarea").on("focus", function() { $(this).closest(".m-error").removeClass("m-error") })
            })
        }

        function d(a, b) { $.isFunction($.fn.uniform) && ($(a).not(".b-pricing_switcher input").uniform(), "undefined" != typeof b && $(b).uniform({ selectClass: "e-select", selectAutoWidth: !1 })) }

        function e(a) { $.isFunction($.fn.placeholder) && $(a).placeholder() }
        return { init: b, makePlaceholders: e, styleControls: d, validate: c }
    }(window),
    formNotifications = function() {
        "use strict";

        function a(a, b, c, d) {
            var e = this.find(".b-form_message").length ? this.find(".b-form_message") : $('<div class="b-form_message"></div>').prependTo(this),
                f = b ? "success" : "error";
            e.height(e.height()).html('<div class="b-form_message_inner"><div class="b-form_message_balloon b-form_message_balloon__' + f + '">' + a + "</div></div>"), e.toggleClass("b-form_message__show", !0).animate({ height: e.find(".b-form_message_inner").height(), paddingBottom: c ? 0 : e.css("padding-bottom") }, 200, "easeOutQuart", function() { $(this).css({ height: "" }) }), c && e.next("form").toggleClass("b-form__hide", !0).slideUp({ duration: 300, easing: "easeOutQuart" }), "function" == typeof d && d.call(this)
        }

        function b() {
            var a = this.find(".b-form_message").length ? this.find(".b-form_message") : $('<div class="b-form_message"></div>').prependTo(this);
            a.slideUp({ duration: 300, easing: "easeOutQuart" })
        }

        function c(a) {
            var b = this.closest(".b-form_box_field");
            b.find(".b-form_box_field" + e.errorSuffix).remove(), b.append('<div class="b-form_box_field' + e.errorSuffix + '">' + a + "</div>"), setTimeout(function() { b.addClass(e.errorClass) }, 100)
        }

        function d() {
            var a = this.closest(".b-form_box_field");
            a.removeClass(e.errorClass), a.find(".b-form_box_field" + e.errorSuffix).remove()
        }
        var e = { errorClass: "m-error", errorSuffix: "_error" },
            f = { required: "This is required field", conditional: { "default": "Input does not coincide", credit: "Invalid credit card number", passwords: "The passwords entered do not match" }, pattern: { "default": "Invalid data format", email: "Invalid email address", phone: "Invalid phone number" }, submit: { success: "Thank you. We will contact you shortly.", error: "Error." } };
        return { labels: f, showErrorLabel: c, showMessage: a, hideErrorLabel: d, hideMessage: b }
    }(window),
    xhrFormHandler = function(a) {
        "use strict";

        function b(b) {
            var e = this,
                f = "";
            if ("boolean" == typeof b.fields && b.fields) b.captcha ? (f = b.msg || formNotifications.labels.submit.success, formNotifications.showMessage.call(this.closest(".b-form"), f, !0, b.hideForm)) : (f = b.msg || formNotifications.labels.submit.error, d.call(this, b));
            else if ("object" == typeof b.fields) {
                var g = " Некорректно заполнены поля: ";
                $.each(b.fields, function(a) {
                    var b = e.find('[name="' + a + '"]').attr("placeholder") || e.find('[name="' + a + '"]').closest(".b-form_box").find(".b-form_box_title").text().replace(" *", "");
                    g += "&laquo;" + b + "&raquo;, "
                }), f = b.msg || formNotifications.labels.submit.error + g.substring(0, g.length - 2) + ".", d.call(this, b), formNotifications.showMessage.call(this.closest(".b-form"), f, !1, !1, function() { c(e, b.fields) })
            } else "console" in a && (console.log("Invalid response format form handler"), console.log(b))
        }

        function c(a, b) { $.each(b, function(b, c) { formNotifications.showErrorLabel.call(a.find('[name="' + b + '"]'), c, 0) }) }

        function d(a) { this.find('[name*="captcha"]').val(""), this.find("img").attr("src", a.captchaImg), a.captcha || formNotifications.showErrorLabel.call(this, forms.msg.captcha, 0) }
        return { response: b }
    }(window),
    mailChimp = function() {
        "use strict";
        var a = this;
        $.ajax({
            url: a.attr("action"),
            method: a.attr("method"),
            data: a.serialize(),
            dataType: "json",
            success: function(b) {
                var c;
                "error" != b.status ? (c = "<h3>Thank You!</h3><p>You have subscribed to our newsletter.</p>", formNotifications.showMessage.call(a.closest(".b-form"), c, !0, !0)) : (c = "<h3>Error!</h3>" + formNotifications.labels.pattern.email, formNotifications.showMessage.call(a.closest(".b-form"), c, !1, !1))
            }
        })
    },
    modules = function() {
        "use strict";

        function a() {
            var a = $('a[href^="#"]:not([class*="js-spoiler"] a, [class*="js-tabs"] a)'),
                b = $("html, body");
            a.on("click", function(a) {
                var c = $(this).attr("href");
                c.length > 1 && (a.preventDefault(), $(c).length && b.stop().animate({ scrollTop: $(c).offset().top - 70 }, 1500, "easeInOutExpo"))
            })
        }

        function b(a) {
            function b(a) { this.toggleClass("opened", !1), this.find(a + "-toggle").html(this.find(a + "-toggle").data("closed") || this.find(a + "-toggle").html()), this.find(a + "-text").slideUp({ duration: 300, easing: "easeOutQuart" }) }

            function c(a) { this.toggleClass("opened", !0), this.find(a + "-toggle").html(this.find(a + "-toggle").data("opened") || this.find(a + "-toggle").html()), this.find(a + "-text").slideDown({ duration: 300, easing: "easeOutQuart" }) }
            $(a.selector).each(function() {
                var d = $(this),
                    e = (d.find(a.selector + "-text"), d.find(a.selector + "-toggle"));
                d.not(".opened").find(a.selector + "-text").hide(), e.on("click.spoiler", function(d) {
                    d.preventDefault();
                    var e = $(this).closest(a.selector),
                        f = e.hasClass("opened") && e.find(a.selector + "-text").is(":visible");
                    f ? b.call(e, a.selector) : c.call(e, a.selector), e.data("closeNeighbors") && e.siblings(a.selector).each(function() { b.call($(this), a.selector) })
                })
            })
        }

        function c(a) {
            $(a.selector).each(function() {
                var b = $(this),
                    c = $(this).find(a.selector + "-wrapper").filter(function() {
                        return b[0] == $(this).closest(a.selector)[0]
                    }),
                    d = $(this).find(a.selector + "-toggle a.current");
                d.length || $(this).find(a.selector + "-toggle a:first").addClass("current");
                var e = $(this).find(a.selector + "-toggle a.current").attr("href"),
                    f = c.find(a.selector + "-page").filter(function() {
                        return c[0] == $(this).closest(a.selector + "-wrapper")[0]
                    }),
                    g = c.find(a.selector + "-page" + e).filter(function() {
                        return c[0] == $(this).closest(a.selector + "-wrapper")[0]
                    });
                f.toggleClass("visible", !1), g.toggleClass("visible", !0), $(this).find(a.selector + "-toggle").filter(function() {
                    return b[0] == $(this).closest(a.selector)[0]
                }).find('a[href*="#"]').click(function(b) {
                    return b.preventDefault(), c.css({ height: g.outerHeight(!0) }), $(this).closest(a.selector + "-toggle").find("a").removeClass("current"), $(this).addClass("current"), console.log(f, g), g = $(this).closest(a.selector).find(a.selector + "-page" + $(this).attr("href")), f.toggleClass("visible", !1), g.toggleClass("visible", !0), c.animate({ height: g.outerHeight(!0) }, 500, "easeOutQuart", function() { $(this).css({ height: "" }) }), "undefined" != typeof a.onToggle && a.onToggle && a.onToggle($(this), $(this).closest(a.selector).find(a.selector + "-page" + $(this).attr("href"))), !1
                })
            })
        }

        function d(a) {
            function b(a) {
                return a.canPlayType('video/webm; codecs="vp8, vorbis"')
            }

            function c(a) {
                return a.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"')
            }

            function d(a) {
                return a.canPlayType('video/ogg; codecs="theora, vorbis"')
            }
            var e, f, g, h = !!document.createElement("video").canPlayType,
                i = "ontouchstart" in document.documentElement,
                j = document.getElementById(a);
            j && (!h || i ? (j.style.display = "none", j.parentNode.removeChild(j)) : setTimeout(function() { e = j.getAttribute("data-src"), f = e.lastIndexOf("."), -1 != f && (e = e.substring(0, f)), g = document.createElement("source"), b(j) ? (g.src = e + ".webm", g.type = 'video/webm; codecs="vp8, vorbis') : c(j) ? (g.src = e + ".mp4", g.type = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2') : d(j) && (g.src = e + ".ogv", g.type = 'video/ogg; codecs="theora, vorbis'), j.appendChild(g), j.load(), j.play() }, 0))
        }
        return { hashNav: a, spoilers: b, tabs: c, video: d }
    }(window),
    plugins = function(a) {
        "use strict";

        function b() {
            if ($.isFunction($.fn.flexslider) && !helpers.mobile()) {
                var a = $(".b-counters_item_digits");
                a.each(function() { $(this).counterUp({ time: 1e3 }) })
            }
        }

        function c() {
            var b = $(".b-countdown");
            b.each(function() {
                var c = $(this),
                    d = new Date(b.data("startAt"));
                $.isFunction($.fn.countDown) && c.countDown({ targetDate: { day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear(), hour: d.getHours(), min: d.getMinutes(), sec: d.getSeconds() }, onComplete: function() { "function" == typeof a[c.data("complete")] ? a[c.data("complete")].call(c) : c.toggleClass("b-countdown__complete", !0) }, omitWeeks: !0 })
            })
        }

        function d() {
            if ($.isFunction($.fn.owlCarousel)) {
                var a = "b-carousel";
                $("." + a).each(function() {
                    var a = $(this);
                    a.owlCarousel({ margin: 0, loop: !0, autoplay: a.data("auto") || !1, autoplayTimeout: 1e3 * a.data("interval") || 1e4, mouseDrag: !1, touchDrag: !0, pullDrag: !1, freeDrag: !1, dots: !1, navText: ["Prev", "Next"], navClass: ["owl-prev", "owl-next"], responsive: { 0: { items: a.data("xs"), nav: a.find(".b-carousel_item").length >= a.data("xs") }, 480: { items: a.data("sm"), nav: a.find(".b-carousel_item").length >= a.data("sm") }, 768: { items: a.data("md"), nav: a.find(".b-carousel_item").length >= a.data("md") }, 970: { items: a.data("lg"), nav: a.find(".b-carousel_item").length >= a.data("lg") } } })
                })
            }
        }

        function e() {
            if ($.isFunction($.fn.flexslider)) {
                var a = "b-slider";
                $("." + a).each(function() {
                    var b = $(this);
                    b.find("." + a + "_inner > div").length > 1 && b.flexslider({ namespace: a + "_", selector: "." + a + "_inner > div", controlsContainer: b.parent(), allowOneSlide: !1, animation: b.data("animation") || "slide", animationLoop: !0, animationSpeed: b.data("speed") || 400, slideshow: b.data("auto") || !1, slideshowSpeed: 1e3 * b.data("interval") || 5e3, showNearby: b.data("showNearby") || !1, directionNav: b.data("arrows") ? b.data("arrows") : !0, controlNav: b.data("control") || !1, controlNavLoop: b.data("controlLoop") || !1, controlNavPlace: b.data("controlPlace") || "after", progressLine: b.data("progress") || !1, thumbCaptions: b.data("controlCaptions") || !1, thumbBg: b.data("thumbsAsBg") || !1, thumbCount: b.data("thumbsCount") ? b.data("thumbsCount").split(",") : [1, 2, 2, 2], pauseOnAction: !0, prevText: "", nextText: "", smoothHeight: !0, after: function(a) {!a.playing && a.data("auto") && a.play() } })
                })
            }
        }

        function f() {
            skrollr.init({
                forceHeight: !1,
                mobileCheck: function() {
                    return !1
                }
            })
        }

        function g() {
            function a() { $(".b-header_nav a").removeClass("active"), forms.init(".b-leaflet_box") }
            var b = $(".js-popup"),
                c = $(".js-form"),
                d = $(".js-popup-video");
            b.leafLetPopUp({ animationStyleOfBox: "scale", animationStyleOfChange: "slide", boxWidth: 1e3, boxHorizontalGutters: 15, closeBtnClass: "i-icon i-ion-ios-close-outline", closeBtnLocation: "overlay", directionBtnClass: ["i-icon i-ion-ios-arrow-back", "i-icon i-ion-ios-arrow-forward"], overlayOpacity: .75, afterLoad: a }), c.leafLetPopUp({ animationStyleOfBox: "flip3d", animationStyleOfChange: "slide", boxWidth: 600, boxHorizontalGutters: 15, closeBtnClass: "i-icon i-ion-ios-close-outline", closeBtnLocation: "overlay", directionBtnClass: ["i-icon i-ion-ios-arrow-back", "i-icon i-ion-ios-arrow-forward"], overlayOpacity: .75, afterLoad: a }), d.leafLetPopUp({ animationStyleOfBox: "scale", animationStyleOfChange: "slide", boxWidth: 800, boxHorizontalGutters: 15, content: !0, closeBtnClass: "i-icon i-ion-ios-close-outline", closeBtnLocation: "overlay", directionBtnClass: ["i-icon i-ion-ios-arrow-back", "i-icon i-ion-ios-arrow-forward"], overlayOpacity: .75, afterLoad: a }), $("body").on("click", ".js-popup-close", function() { $(this).leafLetPopUp("hide") })
        }

        function h() {
            function a(a) { this.waypoint("destroy").waypoint($.proxy(function() { this.addClass("b-section__show") }, this), { offset: a }) }
            var b = $("#speakers");
            helpers.mobile() || b.each(function() { a.call($(this).addClass("b-section__wp"), "50%") })
        }
        return { carousel: d, counterUp: b, countDown: c, parallax: f, popup: g, sliders: e, wayPoint: h }
    }(window)